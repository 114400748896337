<template>
  <div class="sensors-modal">
    <div class="sensors">
      <div class="sensors-header">
        <div class="sensors-header-top">
          <div class="sensors-header-top_title">
            {{ $t('objectsSensors.copySensors') }}
          </div>
          <div class="sensors-header-top_icon" @click="$emit('input', false)">
            <CloseIcon fill="#292929" width="12px" heigth="12px" />
          </div>
        </div>
        <div class="sensors-header-body header">
          <div class="header-search">
            <SearchIcon style="min-width: 27px" width="27px" height="20px" />
            <el-select v-model="searchObjectValue" placeholder="Объект" height="36px" truck-by="text">
              <el-option class="text-ellipsis" v-for="(item, key) in searchObjectColumns" :key="key" :value="item" :label="item.text || '-'" />
            </el-select>
            <el-input v-model="searchValue" height="36px" :placeholder="$t('search_company')" all-objects openObjects type="text" @input="searchSensors" />
          </div>
        </div>
        <div @click="clearSelected" class="sensors-header-clear clear">
          <div class="clear-name">{{ $t('objectsSensors.clearSelect') }}</div>
          <div class="clear-count">{{ count }}</div>
        </div>
      </div>
      <div class="sensors-body">
        <div class="sensors-body-loader" v-if="loader">
          <UiLoader />
        </div>
        <div v-else-if="objectsBlocks.length" class="wrapper">
          <div v-if="showFirstForm">
            <ObjectSensors v-for="(item, idx) in objectsBlocks" :key="idx" :item="item" :loading="item.loadingItem" @openModal="openItem(item, $event)" @selectSensor="selectSensor" />
          </div>
          <SensorType v-else />
        </div>
        <div class="sensors-body-nodata" v-else>
          {{ $t('objectsSensors.noData') }}
        </div>
      </div>
      <div class="sensors-footer">
        <!-- <el-button
          class="objects-footer__cancel border-0"
          :disabled="loaderButton"
          @click="$emit('input', false)"
        >
          {{ $t('new_units.footer.cancel') }}
        </el-button> -->
        <el-button v-if="showFirstForm" :loading="loaderButton" class="objects-footer__save border-0" @click="addSensors">
          {{ $t('dealerTranslater.next') }}
        </el-button>
        <el-button v-else :loading="loaderButton" :disabled="!getSensorCopyType" class="objects-footer__save border-0" @click="addSensors">
          {{ $t('objectsSensors.copy') }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from '@/components/admin-panel/components/assets/icons/CloseIcon.vue'
import ObjectSensors from '@/components/unitsNew/components/modalSensors/ObjectSensors.vue'
import SensorType from './SensorType.vue'
import SearchIcon from '@/components/unitsNew/assets/icons/search.vue'
import { useStore } from '@/store/store'
import { objectsApi } from '@/api'
import UiLoader from '@/components/unitsNew/components/modalSensors/components/loader'
import { createVuexHelpers } from 'vue2-helpers'
import { mapGetters, mapMutations } from 'vuex'
const { useGetters, useMutations, useActions } = createVuexHelpers()

const Store = useStore()
const { formattedCurrentTime } = useGetters('login', ['formattedCurrentTime'])

export default {
  components: {
    CloseIcon,
    ObjectSensors,
    SearchIcon,
    UiLoader,
    SensorType
  },

  props: {
    modelValue: {
      type: Boolean
    }
  },

  data() {
    return {
      showFirstForm: true,
      loading: false,
      closeScrollPagination: false,
      fromPagination: 0,
      countPagination: 1,
      timeOutPagination: null,
      timeOut: null,
      loader: true,
      loaderButton: false,
      searchValue: '',
      objectsBlocks: [],
      searchObjectColumns: [
        { text: 'Все', value: '' },
        { text: 'Название объекта', value: 'name' },
        {
          text: 'Протокол',
          value: 'terminal_type_key'
        },
        { text: 'IMEI', value: 'imei' },
        { text: 'sim2', value: 'phonenumber' },
        { text: 'Датчики', value: 'sensors_count' },
        { text: 'Группы', value: 'groups' }
      ],
      searchObjectValue: { text: 'Все', value: '' }
    }
  },

  computed: {
    ...mapGetters({
      getSelectedUnits: 'units/getSelectedUnits',
      getSensorCopyType: 'units/getSensorCopyType',
      getActiveObject: 'units/activeObject',
      sensorCopyTypes: ['dictionary/sensor_copy_type']
    }),
    count() {
      const selectedItems = []
      this.objectsBlocks.forEach(val => {
        if (val.sensors && val.sensors.length) {
          val.sensors.forEach(item => {
            if (item.checked) {
              selectedItems.push(item)
            }
          })
        }
      })

      return selectedItems.length
    }
  },

  methods: {
    ...mapMutations('units', ['setSensorCopyType', 'SET_ACTIVE_OBJECT']),

    searchSensors() {
      if (this.timeOut) {
        clearTimeout(this.timeOut)
      }

      this.timeOut = setTimeout(() => {
        this.loader = true
        const formData = {
          conditions: [
            {
              field: this.searchObjectValue.value,
              value: this.searchValue
            }
          ],
          fields: ['id', 'name']
        }
        objectsApi.searchObject({ formData }, response => {
          this.objectsBlocks = response.data.list
          this.loader = false
        })
      }, 500)
    },

    clearSelected() {
      this.objectsBlocks = this.objectsBlocks.map(val => {
        if (val.sensors) {
          val.sensors = val.sensors.map(item => {
            this.$set(item, 'checked', false)

            return item
          })
        }

        return val
      })
    },

    openItem(val, item) {
      if (!(item.sensors && item.sensors.length)) {
        val.loadingItem = true
        objectsApi.unitsItemSensors(item.id).then(res => {
          val.loadingItem = false
          this.objectsBlocks = this.objectsBlocks.map(i => {
            if (item.id === i.id) {
              Object.assign(i, { sensors: res })
            }

            return i
          })
        })
      }
    },

    selectSensor(item, sensor) {
      this.objectsBlocks = this.objectsBlocks.map(val => {
        if (item.id === val.id) {
          if (val.sensors) {
            val.sensors = val.sensors.map(sensorItem => {
              if (sensor && sensorItem && sensor.id === sensorItem.id) {
                const { checked } = sensorItem
                this.$set(sensorItem, 'checked', !checked)
              }

              return sensorItem
            })
          }
        }
        return val
      })
    },

    addSensors() {
      this.loaderButton = true

      const ids = this.objectsBlocks
        .filter(item => item.sensors) // GET UNITS WITH SENSORS
        .map(item => {
          // GET SELECTED SENSORS
          const checked = item.sensors.filter(sensor => sensor.checked)
          if (checked.length) return checked
        })
        .filter(item => item) // CLEAR VALUES FRON UNDEFINED DUE TO map function
        .flat() // SPREAD ALL ARRAYS TO ONE
        .map(item => item.id) // GET ONLY SENSORS IDS

      if (ids.length) {
        this.showFirstForm = false
        this.loaderButton = false

        const selectedUnits = this.getSelectedUnits
        const sensorCopyType = this.getSensorCopyType

        let activeCopyType = null
        let sensors = []
        let units = []

        this.sensorCopyTypes.map(item => {
          if (item.key === sensorCopyType) {
            activeCopyType = item
          }
        })
        ids.map(sensorId => {
          sensors.push({
            id: sensorId
          })
        })

        const activeUnitId = this.getActiveObject?.id

        if (activeUnitId) {
          units.push({
            id: activeUnitId
          })
        } else {
          selectedUnits.map(unit => {
            units.push({
              id: unit.id
            })
          })
        }

        if (activeCopyType && sensors.length > 0 && units.length > 0) {
          const payload = {
            units,
            sensors,
            sensor_copy_type: activeCopyType
          }
          // we need to emit the type of sensors first then we emit onSubmit
          objectsApi
            .copySensors(payload)
            .then(response => {
              this.$showSuccess() // this.$t('objectsSensors.copy.success')
              this.$emit('close')
              this.setSensorCopyType(null)
            })
            .catch(e => {
              this.$showError(e?.response?.data?.message)
            })
            .finally(() => {
              this.loaderButton = false

              if (activeUnitId) {
                objectsApi.getObject(
                  activeUnitId,
                  response => {
                    this.SET_ACTIVE_OBJECT(JSON.parse(JSON.stringify(response.data)))
                  },
                  error => {
                    instance.proxy.$showError(error.response.data.message)
                  }
                )
              }
            })
        }
      } else {
        this.$emit('input', false)
        this.loaderButton = false
        this.$emit('onSubmit', this.objectsBlocks)
      }
    },

    callApi() {
      const formData = {
        from: this.fromPagination * 100,
        count: 100,
        conditions: [
          {
            field: this.searchObjectValue.value,
            value: this.searchValue
          }
        ],
        fields: ['id', 'name']
      }
      objectsApi.searchObject(
        { formData },
        response => {
          this.loader = false
          this.objectsBlocks = this.objectsBlocks.concat(response.data.list)
          if (this.objectsBlocks.length >= response.data.max || !response.data.list.length) {
            this.closeScrollPagination = true
          }
        },
        () => {
          this.loader = false
        }
      )
      this.fromPagination += 1
    }
  },

  mounted() {
    this.callApi()

    const listItem = document.querySelector('.sensors-body')

    listItem.addEventListener('scroll', () => {
      if (Math.ceil(listItem.scrollTop) + Math.ceil(listItem.clientHeight) >= listItem.scrollHeight && !this.closeScrollPagination) {
        this.callApi()
      }
    })
  }
}
</script>

<style scoped lang="scss">
.sensors-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10001;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
}
.sensors {
  height: calc(100% - 160px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 12px;
  padding: 18px 0;
  width: 400px;
  box-sizing: border-box;
  overflow: hidden;

  &-header {
    border-bottom: 1px solid #cdd4dd;
    display: flex;
    flex-direction: column;
    padding: 0 20px 12px 20px;

    &-top {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &_icon {
        border-radius: 100%;
        width: 28px;
        height: 28px;
        background: #edeff3;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }

    &-body {
      margin: 21px 0 9px 0;
    }
    .header {
      &-search {
        display: flex;
        align-items: center;
        background: #edeff3;
        border-radius: 12px;
        padding-left: 8px;

        :deep(.select-header__text.placeholder) {
          color: #3a7cca;
          //font-size: 14px;
          //font-style: normal;
          //font-weight: 600;
          //line-height: normal;
        }
      }
    }

    &-clear {
      display: flex;
      gap: 6px;
      justify-content: flex-end;
      align-items: center;
      cursor: pointer;
      width: max-content;
      margin: 0 0 0 auto;
    }
    .clear {
      &-name {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 19px;
        color: #3a7cca;
      }

      &-count {
        border-radius: 100%;
        background: #3a7cca;
        color: white;
        width: 17px;
        height: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 14px;
        line-height: 16.8px;
      }
    }
  }

  &-body {
    overflow: hidden;
    overflow-y: auto;
    height: 100%;
    max-width: 400px;

    :deep(.objects-block) {
      .object-name {
        max-width: 320px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &-nodata {
      text-align: center;
    }
    &-loader {
      height: 100%;
      min-height: 100px;
    }
  }
  &-footer {
    border-top: 1px solid #cdd4dd;
    padding: 11px 20px 0 20px;
    display: flex;
    justify-content: center;
    gap: 12px;
  }

  :deep(.el-select) {
    input {
      padding-top: 9px !important;
    }
  }

  :deep(.el-input) {
    input {
      padding: 9px 8px 10px 8px !important;
      outline: none;
      border: none;
    }
  }
}
</style>
